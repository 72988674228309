// ServiceCard.jsx
import React, { useState } from "react";
import '../css/ServiceCard.css';

const ServiceCard = ({ title, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div 
      className="service-card" 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.27)' }}
    >
      <h3 style={{ color: isHovered ? 'white' : '#262626' }}>{title} <span>&#8594;</span></h3>
      <p style={{ color: isHovered ? 'white' : '#262626' }}>{description}</p>
    </div>
  );
};

export default ServiceCard;