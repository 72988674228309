import React from 'react';
import './Services.css';

const Access = () => {
    return (
        <div className="services-section" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
            <h1>Access Control</h1>
            <h2 className='services-center'>The most basic but critical aspect of any secure location is controlling who gets in and who stays out</h2>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>PROGRAMMING</h3>
                <p className='services-right'>
                    Our systems employ an intuitive and easy-to-use web-based GUI interface to make management simple and accessible anywhere around the world 
                </p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>HARDWARE</h3>
                <p className='services-right'>
                    From a simple fob reader to a fully-fledged interactive tablet intercom, we have an interface solution that fits your unique conditions  
                </p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>CREDENTIALS</h3>
                <p className='services-right'>
                    While a simple PIN code is still an effective option, we also offer card, fob, and app-based entry methods to fulfil everyone's needs  
                </p>
            </div>
            <hr className='custom-divider' />
        </div>
    );
};

export default Access;