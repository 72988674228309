import { useState } from "react";
import "../css/ContactForm.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.name || !formData.email || !formData.message) {
      alert("Please fill in all fields.");
      return;
    }

      // ✅ Check localStorage for submission count
    let submissionCount = localStorage.getItem("formSubmissions") || 0;
    if (submissionCount >= 1) {
      alert("You've reached the maximum number of submissions!");
      return;
    }
  
    try {
      const response = await fetch("https://alphawifi.com/send-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        submissionCount++;
        localStorage.setItem("formSubmissions", submissionCount); // ✅ Store new count
        alert("Form submitted successfully!");

        setSubmitted(true);
        setFormData({ name: "", email: "", message: "" });
      } else {
        alert("Failed to send email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  return (
    <div className="contact-form-container">
      <h2>Contact Us</h2>
      {submitted && <p className="success-message">Form submitted successfully!</p>}
      <form onSubmit={handleSubmit} className="contact-form">
        <div>
          <label>NAME</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>EMAIL</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>MESSAGE</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            required
          ></textarea>
        </div>
        <button
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;