import React from 'react';
import '../css/Home.css';

const Home = () => {
  return (
    <section id="home" className="home-section">
      <h1 style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>IP-Based Solutions</h1>
      <p style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>Providing a Cutting-Edge Suite of Services to Colorado for Over 10 Years</p>
    </section>
  );
};

export default Home;