import React from "react";
import '../../Services/Services.css'

const AboutUs = () => {
    return (
        <div className="services-section" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>INSTALLATION</h3>
                <p className='services-right'>From new construction to a century old and from single family to multi-building campuses no job is too big, small, new or old</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>RESPONSE</h3>
                <p className='services-right'>Our seasoned and skilled technicians are on-call 24/7/365 to get critical equipment back online at a moment's notice</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>SECURITY</h3>
                <p className='services-right'>We use equipment trusted by institutions the world over to give you peace of mind in the ever-evolving internet landscape</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>SUPPORT</h3>
                <p className='services-right'>Dealing with support tickets and chat-bots is frustrating and often unhelpful, that's why we pride ourselves on our direct to live representative support</p>
            </div>
            <hr className='custom-divider' />
        </div>
    )
};

export default AboutUs;