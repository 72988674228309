import { Link } from 'react-router-dom';
import React from "react";
import './Navbar.css';
import AlphaWiFiLogo from '../Utils/awifi-logo.png';

const Navbar = () => {



  const handleScroll = (event) => {
    event.preventDefault(); // Prevent the default anchor behavior
    const targetId = event.target.getAttribute("href").substring(1); // Get the ID without the hash

    const targetElement = document.getElementById(targetId);
  
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/"><img src={AlphaWiFiLogo} alt="AWiFiLogo" style={{width: '50px', height: 'auto', paddingLeft: '1%', paddingRight: '10px'}} /></Link>
        <Link to="/" className="navbar-title">AlphaWiFi</Link>

        <ul className="nav-links">
          <li><Link to="/">HOME</Link></li>
          <li><a href='#services' onClick={handleScroll}>SERVICES</a> </li>
          <li><a href='#contact' onClick={handleScroll}>CONTACT</a></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;