import React from 'react';
import './Services.css';

const WiFi = () => {
    return (
        <div className="services-section" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
            <h1>Networks</h1>
            <h2 className='services-center'>We have been installing, monitoring, managing, and upgrading wired and wireless networks of all sizes for over a decade, all with the goal of connecting you seamlessly and reliably. </h2>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>SECURITY</h3>
                <p className='services-right'>Assistance in meeting regulatory requirements such as GDPR, HIPAA, and PCI-DSS. Deployment and configuration of Layer 3 firewalls to protect against unauthorized access and Intrusion Prevention Systems (IPS) to detect and block malicious activities in real-time</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>REMOTE</h3>
                <p className='services-right'>Deployment of cloud-managed network infrastructure designed for high efficiency and minimal downtime offering seamless management and monitoring</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>INTEGRATION</h3>
                <p className='services-right'>Takeover of existing networks for maintenance, updates, or reconfiguration with proactive issue identification and resolution to improve network reliability and performance</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>WISP</h3>
                <p className='services-right'>We offer low latency, high bandwidth wireless internet service provided via Point-to-Point (PTP) radio infrastructure to select locations all over the Metro Denver area; no contracts required</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>CELLULAR</h3>
                <p className='services-right'>We have partnered with T-Mobile to provide 5G & LTE connections to clients all across Colorado. For any edge case involving the need for an internet connection, we have you covered</p>
            </div>
            <hr className='custom-divider' />
        </div>
    );
};

export default WiFi;