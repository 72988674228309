import React from 'react';
import './Services.css';

const Camera = () => {
    return (
        <div className="services-section" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
            <h1>Surveillance</h1>
            <h2 className='services-center'>Protecting your property starts with a robust and reliable surveillance system that's also easy to use</h2>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>CAMERAS</h3>
                <p className='services-right'>From optical zoom to PTZ or a full 360, we have the hardware to capture any scene in any conditions</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>A.I. DETECTION</h3>
                <p className='services-right'>We use latest-generation, industry leading cameras offering A.I. detection giving you tools like facial and license plate recognition to track threats and find the footage that matters</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>PLAYBACK</h3>
                <p className='services-right'>Whether you need multi-display onsite monitoring, around the world cloud-based viewing, or something in-between, our systems can be tailored to fit your unique requirements</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>RECORDING</h3>
                <p className='services-right'>Our systems can be configured with multi-disk RAID-based recording as well as remote and cloud-based backups ensuring your valuable footage is always safe</p>
            </div>
            <hr className='custom-divider' />
        </div>
    );
};

export default Camera;