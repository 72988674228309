import React from 'react';
import './Services.css';

const Automation = () => {
    return (
        <div className="services-section" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
            <h1>Automation</h1>
            <h2 className='services-center'>
              We believe that every person should be able to monitor and control the vital aspects of their property remotely, providing not only peace of mind but tangible cost savings
            </h2>
            <hr className='custom-divider' />
            <div className='section-display'>
              <h3 className='services-left'>WATER</h3>
              <p className='services-right'>
                Leak sensors placed near water sources around the property notify you within moments of a potentially dangerous and expensive leak or flood. With additional hardware, you can remotely shut off the water altogether
              </p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
              <h3 className='services-left'>ENERGY</h3>
              <p className='services-right'>
                Current clamps placed on the main breaker or on individual circuits give you the ability to monitor energy usage down to the watt-hour, with the added capability to track down costly energy leaks
              </p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
              <h3 className='services-left'>ELECTRICAL</h3>
              <p className='services-right'>
                Smart relays placed on lighting and other electrical infrastructure give you not only remote control but the ability to automate based on factors such as weather conditions, motion sensors, and much more
              </p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
              <h3 className='services-left'>HVAC</h3>
              <p className='services-right'>
              Temperature sensors placed in climate-controlled zones around the property give you the ability to monitor and automate HVAC like never before, providing valuable cost savings
              </p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
              <h3 className='services-left'>IRRIGATION</h3>
              <p className='services-right'>
                With a combination of smart zone valves and an array of sensors measuring soil moisture, forecast, sun position, and many more. We can design a system that reduces costs and saves water
              </p>
            </div>
            <hr className='custom-divider' />
        </div>
    );
};

export default Automation;