import React from "react";
import '../css/Contact.css';
import TMobileLogo from '../../Utils/TFB_Registered-Partner_Logo_.png';

const Contact = () => {
  return (
    <div id='contact' className="contact">
      <div  className="contact-container">
        <ul className='contact-info' >
          <img src={TMobileLogo} alt="TMobileLogo" style={{width: '15rem', height: 'auto', paddingRight: '10px', paddingBottom: "5%"}} />
          <li style={{color: "white"}} >-</li>
          <li>ALPHAWIFI LLC</li>
          <li>1776 S JACKSON ST, STE 303</li>
          <li>DENVER CO 80210</li>
          <li>OFFICE: 303.209.2128</li>
          <li>EMAIL: SUPPORT@ALPHAWIFI.COM</li>
          <li style={{color: "white"}} >-</li>
          <li >
            <a href="/privacypolicy.html" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline', color: '#333333', fontSize: '0.85rem'}}>
              PRIVACY POLICY
            </a>
          </li>
          <li>
            <a href="/cookiepolicy.html" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline', color: '#333333', fontSize: '0.85rem'}}>
              COOKIE POLICY
            </a>
          </li>
          <li>
            <p style={{color: '#333333', fontSize: '0.75rem'}} >
              &copy; {new Date().getFullYear()} ALPHAWIFI LLC. ALL RIGHTS RESERVED.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;