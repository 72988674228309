import React from 'react';
import './Services.css';    // Import the CSS file for this component

const Phone = () => {
    return (
        <div className="services-section" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
            <h1>Telephony</h1>
            <h2 className='services-center'>Our comprehensive range of services ensures that all your communication needs are met efficiently and reliably</h2>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>VOIP</h3>
                <p className='services-right'>Design and implementation of scalable Voice over IP systems that are cost-effective and customizable for businesses of all sizes. Setup of multi-line phone systems for offices, retail spaces, and call centers with support for solutions tailored to remote employees</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>LEGACY</h3>
                <p className='services-right'>Migration from traditional POTS (Plain Old Telephone Service) to modern VoIP alternatives that ensures reliable communication, and can be integrated with decades-old infrastructure with enhanced features and lower costs</p>
            </div>
            <hr className='custom-divider' />
            <div className='section-display'>
                <h3 className='services-left'>SMS</h3>
                <p className='services-right'>Integration of messaging functionality for business communications with support for automated messaging, customer notifications, and two-way text communications</p>
            </div>
            <hr className='custom-divider' />
        </div>
    );
};

export default Phone;