import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Navbar from './Navbar/Navbar';
import Home from './Home/js/Home';
import Contact from './Home/js/Contact';
import ServicesPage from "./Home/js/ServicesPage";
import './App.css';
import WiFi from './Services/WiFi';
import Camera from './Services/Camera';
import Access from './Services/Access';
import Phone from './Services/Phone';
import Automation from './Services/Automation';
import ScrollToTop from './Utils/ScrollToTop';
import AboutUs from './Home/js/AboutUs';
import ContactForm from './Home/js/ContactForm';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={
          <div className="App">
            <Navbar />
            <Home />
            <ServicesPage />
            <AboutUs />
            <ContactForm />
            <Contact />
          </div>} />
        <Route path='/wifi' element={          
          <div className="App">
            <Navbar />
            <WiFi />
            <ServicesPage />
            <ContactForm />
            <Contact />
          </div>} />
        <Route path='/camera' element={
          <div className="App">
            <Navbar />
            <Camera />
            <ServicesPage />
            <ContactForm />
            <Contact />
          </div>} />
        <Route path='/access' element={
          <div className="App">
            <Navbar />
            <Access />
            <ServicesPage />
            <ContactForm />
            <Contact />
          </div>} />
        <Route path='/phone' element={
          <div className="App">
            <Navbar />
            <Phone />
            <ServicesPage />
            <ContactForm />
            <Contact />
          </div>} />
        <Route path='/automation' element={
          <div className="App">
            <Navbar />
            <Automation />
            <ServicesPage />
            <ContactForm />
            <Contact />
          </div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;