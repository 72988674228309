import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceCard from "./ServiceCard";
import '../css/ServicesPage.css';

// AlphaWiFI.com Changes 

// Sub header:  
// Turning the cutting-edge into the commonplace   
// Making the cutting-edge work for you 
// Providing a cutting-edge suite of services to Colorado for over 10 years 
// Integrating the cutting edge to work for you 

// What we do 

// Wired & Wireless Networks:  
// Using the latest technology to connect you 


const ServicesPage = () => {
  const services = [
    {
      url: "/wifi",
      title: "Networks",
      description: "Using the Latest Technology to Connect You",
    },
    {
      url: "/automation",
      title: "Automation",
      description: "Monitor and Control Every Aspect",
    },
    {
      url: "/camera",
      title: "Surveillance",
      description: "A.I. Driven Peace of Mind",
    },
    {
      url: "/access",
      title: "Access Control",
      description: "Making Security Seamless",
    },
    {
      url: "/phone",
      title: "Telephony",
      description: "Communication Like Never Before",
    },
  ];

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

    return (
      <div id="services" className="services-page" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.27)' }}>
          <h1>Services</h1>
          <hr className='services-divider' />
          <div className="services-list">
            {services.map((service, index) => (
              <button
                onClick={() => 
                  navigate(service.url)} 
                  style={{ 
                    backgroundColor: isHovered === index ? "#262626" : "antiquewhite",
                    transition: "background-color 0.3s ease", 
                    cursor: "pointer" ,
                    border: 'none',
                    
                }} 
                  
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)} >
                <ServiceCard
                  key={index}
                  title={service.title}
                  description={service.description}
                />
              </button>
            ))}
          </div>
      </div>
    );
  };


export default ServicesPage;